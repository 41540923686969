import React, { Suspense } from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./styles/common.scss";
import { useContext, useEffect } from "react";
import AuthContext, { AuthStatus } from "./store/auth-context";
import Layout from "./components/Layout/Layout";
import LoadingSpinner from "./components/Layout/LoadingSpinner";
import { authenticateSession } from "./services/AuthService";
import Logger from "js-logger";
import PlayerProvider from "./store/player-context";
import PlayerContainer from "./components/VideoPlayer/PlayerContainer";

const SearchPage = React.lazy(() => import("./pages/SearchPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const PlayerPage = React.lazy(() => import("./pages/PlayerPage"));
const EmbedPage = React.lazy(() => import("./pages/EmbedPage"));

function App(props) {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  Logger.useDefaults();
  if (props.config.env === "prod") {
    Logger.setLevel(Logger.WARN);
  }
  Logger.debug(
    "Starting with following settings: ",
    authCtx.isLoggedIn,
    authCtx.authCheckStatus,
    authCtx.userRoles,
    props.config
  );

  useEffect(() => {
    authenticateSession(props.config, authCtx);
  }, [authCtx, props.config]);

  if (location.pathname.startsWith("/embed/")) {
    return (
      <Suspense
        fallback={
          <div className="page-center">
            <LoadingSpinner />
          </div>
        }
      >
        <PlayerProvider accessToken={authCtx.token}>
          <PlayerContainer maximized />
          <Routes>
            <Route
              path="/embed/:assetId"
              element={<EmbedPage config={props.config} />}
            />
          </Routes>
        </PlayerProvider>
      </Suspense>
    );
  }

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="centered">
            <LoadingSpinner />
          </div>
        }
      >
        {!authCtx.isLoggedIn &&
          authCtx.authCheckStatus === AuthStatus.VALID && (
            <Routes>
              <Route path="*" element={<LoginPage config={props.config} />} />
            </Routes>
          )}
        {authCtx.isLoggedIn && authCtx.userRoles?.length > 0 && (
          <PlayerProvider accessToken={authCtx.token}>
            <PlayerContainer />
            <Routes>
              <Route
                path="/"
                exact
                element={<Navigate to="/kategoria/kaikki" />}
              />
              <Route
                path="/kategoria/:filterId"
                element={<SearchPage config={props.config} />}
              />
              <Route
                path="/haku/:searchParam"
                element={<SearchPage config={props.config} />}
              />
              <Route
                path="/asset/:assetId"
                element={<PlayerPage config={props.config} />}
              />
              <Route
                path="/liiga/haku/:searchParam"
                element={
                  authCtx.userRoles.includes("liiga") ? (
                    <SearchPage config={props.config} />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/liiga/:filterId"
                element={
                  authCtx.userRoles.includes("liiga") ? (
                    <SearchPage config={props.config} />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/liiga"
                element={
                  authCtx.userRoles.includes("liiga") ? (
                    <Navigate to="/liiga/kaikki" />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/mestis/haku/:searchParam"
                element={
                  authCtx.userRoles.includes("mestis") ? (
                    <SearchPage config={props.config} />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/mestis/:filterId"
                element={
                  authCtx.userRoles.includes("mestis") ? (
                    <SearchPage config={props.config} />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/mestis"
                element={
                  authCtx.userRoles.includes("mestis") ? (
                    <Navigate to="/mestis/kaikki" />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/suomiareena/haku/:searchParam"
                element={
                  authCtx.userRoles.includes("suomiareena") ? (
                    <SearchPage config={props.config} />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/suomiareena/:filterId"
                element={
                  authCtx.userRoles.includes("suomiareena") ? (
                    <SearchPage config={props.config} />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route
                path="/suomiareena"
                element={
                  authCtx.userRoles.includes("suomiareena") ? (
                    <Navigate to="/suomiareena/kaikki" />
                  ) : (
                    <LoginPage config={props.config} />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </PlayerProvider>
        )}
      </Suspense>
    </Layout>
  );
}

export default App
