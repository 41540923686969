import React, { useState } from 'react';
import { findValidUser } from '../services/AuthService'

export const AuthStatus = {
  INVALID: "invalid",
  VALID: "valid",
};

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  checkUser: () => {},
  status: AuthStatus.INVALID,
  userRoles: [],
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState(AuthStatus.INVALID);

  const loginHandler = (token, roles) => {
    setStatus(AuthStatus.VALID);
    if (token) {
      setToken(token);
      localStorage.setItem("token", token);
    }
    if (roles) {
      setRoles(roles);
    }
  };

  const logoutHandler = () => {
    setToken(null);
    setRoles([]);
    setStatus(AuthStatus.INVALID);
    localStorage.removeItem("token");
  };

  const userCheckHandler = (userId, token) => {
    const validUser = findValidUser(userId);
    if (validUser) {
      setToken(token);
      localStorage.setItem("token", token);
      return {
        user: "verkosto",
      };
    }
    return false;
  };

  const userIsLoggedIn = !!token;
  const authCheckStatus = status;
  const userRoles = roles;

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    checkUser: userCheckHandler,
    authCheckStatus: authCheckStatus,
    userRoles,
    logout: logoutHandler,
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext
