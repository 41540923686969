import { createContext, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

export const PlayerContext = createContext({
  NordicPlayerInstance: { current: null },
  playerOptions: null,
  setPlayerOptions: () => null,
  areAdsAllowed: false,
  setAreAdsAllowed: () => null,
  isStarting: false,
  setIsStarting: () => null,
  setLoadingPlayerInstance: () => null,
  loadingPlayerInstance: false,
});

export default function PlayerProvider({ accessToken, children }) {
  const NordicPlayerInstance = useRef(null);
  const [playerOptions, setPlayerOptions] = useState(null);
  const [areAdsAllowed, setAreAdsAllowed] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [loadingPlayerInstance, setLoadingPlayerInstance] = useState(false);
  const value = useMemo(
    () => ({
      NordicPlayerInstance,
      playerOptions,
      setPlayerOptions,
      areAdsAllowed,
      setAreAdsAllowed,
      isStarting,
      setIsStarting,
      setLoadingPlayerInstance,
      loadingPlayerInstance,
      accessToken,
    }),
    [
      playerOptions,
      areAdsAllowed,
      isStarting,
      loadingPlayerInstance,
      accessToken,
    ]
  );
  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
}

PlayerProvider.propTypes = {
  accessToken: PropTypes.string,
  children: PropTypes.node.isRequired,
};
