import { lazy, useContext, useEffect } from "react";
import Modal from "../Layout/Modal";
import { PlayerContext } from "../../store/player-context";
import LoadingSpinner from "../Layout/LoadingSpinner";
import "./PlayerContainer.scss";
import { isJwtExpired } from "../../services/Utils";
import AuthContext from "../../store/auth-context";

const NordicPlayer = lazy(() => import("./NordicPlayer"));

export default function PlayerContainer({ maximized = false }) {
  const {
    playerOptions,
    setPlayerOptions,
    setIsStarting,
    loadingPlayerInstance,
    setLoadingPlayerInstance,
    NordicPlayerInstance,
    accessToken,
  } = useContext(PlayerContext);

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    if (accessToken && !maximized && isJwtExpired(accessToken)) {
      console.log("Token has expired, logging out user.");
      logout();
    }
  }, [accessToken, maximized, logout]);

  const closePlayer = () => {
    if (loadingPlayerInstance) return;
    setIsStarting(false);
    setPlayerOptions(null);
  };

  if (!playerOptions) return null;

  const content = (
    <div className="videoplayer-wrapper">
      <div
        id="videoplayer"
        className={`player${maximized ? " maximized" : ""}`}
      >
        {loadingPlayerInstance && (
          <div className="page-center">
            <LoadingSpinner />
          </div>
        )}
        <NordicPlayer
          NordicPlayerInstance={NordicPlayerInstance}
          playerOptions={playerOptions}
          accessToken={maximized ? undefined : accessToken}
          closePlayer={closePlayer}
          setIsStarting={setIsStarting}
          setLoadingPlayerInstance={setLoadingPlayerInstance}
        />
      </div>
    </div>
  );

  return maximized ? content : <Modal>{content}</Modal>;
}
