import { Fragment } from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

const Layout = (props) => {
  return (
    <Fragment>
      <HeaderComponent />
      <main>{props.children}</main>
      <FooterComponent />
    </Fragment>
  );
};

export default Layout;
