import React, { useState } from 'react';

const AppContext = React.createContext({
  playerIsActive: false
})

export const AppContextProvider = (props) => {
  const [playerIsActive, setPlayerIsActive] = useState(false)

  const togglePlayerActive = (playerActive) => {
    setPlayerIsActive(playerActive)
  }

  const contextValue = {
    playerIsActive: playerIsActive,
    togglePlayerActive: togglePlayerActive
  }

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContext
