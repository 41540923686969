// Default values for all environments
// Use ECMAScript 5 syntax

// NOTE: changes to this file are executed only at the start of the build. Restart npm run dev after all changes!
module.exports = {
  debugMode: false,
  httpDefaultTimeout: 20 * 1000, // 20 sec
  analyticsEnabled: false,
  imageService: "//image.katsomo.fi",
  filters: [
    {
      title: "Kaikki",
      name: "kaikki",
    },
    {
      title: "Uutiset",
      name: "uutiset",
      rootCategory: 33001,
    },
    {
      title: "Sport",
      name: "sport",
      rootCategory: 33002,
    },
  ],
  liigaFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 33002079,
    },
    {
      title: "Maalikoosteet",
      name: "maalikoosteet",
      rootCategory: 10001331,
    },
    {
      title: "Huippuhetket",
      name: "huippuhetket",
      rootCategory: 10000707,
    },
    {
      title: "Lehdistötilaisuudet",
      name: "lehdistötilaisuudet",
      rootCategory: 10001388,
    },
    {
      title: "Muut klipit",
      name: "muut",
      rootCategory: 33002079000,
    },
  ],
  liigaTeamFilters: [
    {
      title: "HIFK",
      name: "hifk",
    },
    {
      title: "HPK",
      name: "hpk",
    },
    {
      title: "Ilves",
      name: "ilves",
    },
    {
      title: "Jukurit",
      name: "jukurit",
    },
    {
      title: "JYP",
      name: "jyp",
    },
    {
      title: "Kalpa",
      name: "kalpa",
    },
    {
      title: "KooKoo",
      name: "kookoo",
    },
    {
      title: "Kärpät",
      name: "kärpät",
    },
    {
      title: "Lukko",
      name: "lukko",
    },
    {
      title: "Pelicans",
      name: "pelicans",
    },
    {
      title: "SaiPa",
      name: "saipa",
    },
    {
      title: "Sport",
      name: "sport",
    },
    {
      title: "Tappara",
      name: "tappara",
    },
    {
      title: "TPS",
      name: "tps",
    },
    {
      title: "Ässät",
      name: "ässät",
    },
  ],
  mestisFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 10003599,
    },
    {
      title: "Maalikoosteet",
      name: "maalikoosteet",
      rootCategory: 10003639,
    },
    {
      title: "Otteluklipit",
      name: "otteluklipit",
      rootCategory: 10003601,
    },
    {
      title: "Haastattelut",
      name: "haastattelut",
      rootCategory: 10003602,
    },
    {
      title: "Lisäsisällöt",
      name: "lisäsisällöt",
      rootCategory: 10003603,
    },
  ],
  mestisTeamFilters: [
    {
      title: "FPS",
      name: "fps",
    },
    {
      title: "Hermes",
      name: "hermes",
    },
    {
      title: "Hokki",
      name: "hokki",
    },
    {
      title: "IPK",
      name: "ipk",
    },
    {
      title: "Jokerit",
      name: "jokerit",
    },
    {
      title: "Ketterä",
      name: "ketterä",
    },
    {
      title: "KeuPa HT",
      name: "keupa",
    },
    {
      title: "Kiekko-Espoo",
      name: "kiekko-espoo",
    },
    {
      title: "Kiekko-Pojat",
      name: "kiekko-pojat",
    },
    {
      title: "Kiekko-Vantaa",
      name: "kiekko-vantaa",
    },
    {
      title: "KOOVEE",
      name: "koovee",
    },
    {
      title: "RoKi",
      name: "roki",
    },
    {
      title: "SaPKo",
      name: "sapko",
    },
    {
      title: "TUTO Hockey",
      name: "tuto",
    },
  ],

  channels: {
    1: "MTV3",
    2: "MTV Max",
    3: "Sub",
    4: "MTV Sport 1",
    6: "Ava",
    7: "MTV Juniori",
    9: "MTV3 Sarja",
    10: "MTV3 Scifi",
    11: "MTV Fakta",
    12: "MTV Leffa",
    102: "MTV Sport 2",
    9999: "Katsomo",
  },

  search: {
    // value should be divisible by 3 and 4
    resultsPerPage: 24,
  },

  vman: {
    resolutions: [
      { width: 103, heigth: 58, suffix: "_103x58" },
      { width: 112, heigth: 63, suffix: "_112x63" },
      { width: 122, heigth: 90, suffix: "_122x90" },
      { width: 160, heigth: 90, suffix: "_160x90" },
      { width: 162, heigth: 92, suffix: "_162x92" },
      { width: 221, heigth: 124, suffix: "_221x124" },
      { width: 224, heigth: 126, suffix: "_224x126" },
      { width: 228, heigth: 129, suffix: "_228x129" },
      { width: 246, heigth: 138, suffix: "_246x138" },
      { width: 320, heigth: 180, suffix: "_320x180" },
      { width: 550, heigth: 310, suffix: "_550x310" },
      { width: 568, heigth: 320, suffix: "_568x320" },
      { width: 640, heigth: 360, suffix: "_640x360" },
      { width: 680, heigth: 383, suffix: "_680x383" },
      { width: 950, heigth: 534, suffix: "_950x534" },
      { width: 1600, heigth: 900, suffix: "" },
    ],
  },
};
