import { useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import "./HeaderComponent.scss";

const HeaderComponent = () => {
  const location = useLocation();
  const { isLoggedIn, logout } = useContext(AuthContext);
  let videoPage = false;

  if (location.pathname && location.pathname.indexOf("/asset/") > -1) {
    videoPage = true;
  }

  return (
    <div className={!videoPage ? "header-content" : "header-content nav-only"}>
      <div className="header-content__dimmer">
        <div className="header-content__navi">
          <a href="/">
            <div className="katsomo-logo" />
          </a>
          <div className="header-links-container">
            <div>
              <a
                className="header-content__liigalink"
                rel="noreferrer"
                href="/suomiareena/kaikki"
              >
                SUOMIAREENA &gt;
              </a>
              <a
                className="header-content__liigalink"
                rel="noreferrer"
                href="/liiga/kaikki"
              >
                LIIGA-OSIOON &gt;
              </a>
              <a
                className="header-content__liigalink"
                rel="noreferrer"
                href="/mestis/kaikki"
              >
                MESTIS-OSIOON &gt;
              </a>
            </div>
            {isLoggedIn && (
              <div className="logout" onClick={logout}>
                Kirjaudu ulos
              </div>
            )}
          </div>
        </div>
        {isLoggedIn && !videoPage && (
          <div className="header-content__text">
            <h1 className="header-content__text__title">
              Tervetuloa MTV Videoverkoston kumppanisivulle
            </h1>
            <div className="header-content__text__content">
              Tältä sivulta löydät MTV Videoverkostossa jakelussa olevat
              videoklipit. Katso video klikkaamalla videon kuvaketta. Vanhempia
              videoita löydät helpoiten hakusanaa käyttämällä. Kun olet löytänyt
              haluamasi videoklipin klikkaa “kopioi upotuskoodi” ja kopioi
              upotuskoodi sivullesi. Osan videoista voit myös ladata omalle
              koneellesi.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
