// Default values for all environments
// Use ECMAScript 5 syntax

// NOTE: changes to this file are executed only at the start of the build. Restart npm run dev after all changes!
module.exports = {
  imageProxy: "https://imageproxy.a2d.tv/?source=",
  nordicImageService: "https://tvmedia.image-service.eu-north-1-prod.vmnd.tv",
  nordicApi:
    "https://tvmedia.content-discovery.cf.eu-north-1-preprod.vmnd.tv/api/v1",
  rootId: 1007,
  env: "test",
  allowedUsers: [
    {
      id: "28c7f9de-5edd-41aa-9b4b-cfa9d7a7794c",
      roles: ["verkosto", "liiga", "mestis", "suomiareena"],
    },
    {
      id: "5734187",
      roles: ["verkosto"],
    },
  ],
  filters: [
    {
      title: "Kaikki",
      name: "kaikki",
    },
    {
      title: "Uutiset",
      name: "uutiset",
      rootCategory: 1027,
    },
    {
      title: "Sport",
      name: "sport",
      rootCategory: 1028,
    },
    {
      title: "Lifestyle",
      name: "lifestyle",
      rootCategory: 33,
      subCategories: [33007, 33009, 33010],
    },
    {
      title: "Viihde",
      name: "viihde",
      rootCategory: 33,
      // subCategories: [33005, 33006, 33008],
      wonGenres: ["6.1.2.2", "6.2.1.2", "6.2.2.2", "6.2.2.1"],
    },
  ],
  liigaFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 1653,
    },
    {
      title: "Maalikoosteet",
      name: "maalikoosteet",
      rootCategory: 1655,
    },
    {
      title: "Huippuhetket",
      name: "huippuhetket",
      rootCategory: 1654,
    },
    {
      title: "Lehdistötilaisuudet",
      name: "lehdistötilaisuudet",
      rootCategory: 1657,
    },
    {
      title: "Muut klipit",
      name: "muut",
      rootCategory: 1658,
    },
  ],
  mestisFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 1163,
    },
    {
      title: "Maalikoosteet",
      name: "maalikoosteet",
      rootCategory: 1167,
    },
    {
      title: "Otteluklipit",
      name: "otteluklipit",
      rootCategory: 1164,
    },
    {
      title: "Haastattelut",
      name: "haastattelut",
      rootCategory: 1165,
    },
    {
      title: "Lisäsisällöt",
      name: "lisäsisällöt",
      rootCategory: 1166,
    },
  ],
};
