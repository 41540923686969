const moment = require('moment')

export function isValidLiigaDate(date) {
  return moment(date).isAfter('2020-11-30', 'day')
}

export function isValidMestisDate(date) {
  return moment(date).isAfter('2022-09-01', 'day');
}

export function formatDateTime(timestamp) {
  if (!timestamp) {
    return ''
  }
  return moment(timestamp).format('D.M.YYYY [klo] HH.mm')
}

export function formatDate(timestamp) {
  if (!timestamp) {
    return ''
  }
  return moment(timestamp).format('D.M.YYYY');
}

export function formatDuration(duration) {
  if (!duration) {
    return '';
  }
  const dur = moment.duration(duration, 'seconds').asMilliseconds();
  if (duration > 3600) {
    return moment.utc(dur).format('hh:mm:ss');
  }
  return moment.utc(dur).format('mm:ss');
}

export const handleVideoClick = (
  id,
  subtitle,
  { playerOptions, isStarting, setIsStarting, setPlayerOptions },
  areAdsAllowed = false
) => {
  if (isStarting) return; // Prevent another video start while previous is starting
  if (playerOptions && playerOptions?.id === id) {
    setPlayerOptions(null);
    return;
  }
  setIsStarting(true);
  setPlayerOptions({
    id,
    subtitle,
    areAdsAllowed,
  });
};

export const getEmbedBaseUrl = () => `${window.location.origin}/embed/`;

export function isJwtExpired(jwt) {
  try {
    const [, payload] = jwt.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const exp = decodedPayload?.exp || 0;
    return Date.now() >= exp * 1000;
  } catch (error) {
    console.error("Could not parse JWT token!");
    console.error(error);
    return false;
  }
}