import config from "../config";

const retrieveToken = () => {
  return localStorage.getItem("token");
};

const clearToken = () => {
  localStorage.removeItem("token");
};

export const findValidUser = (userId) => {
  return config.allowedUsers.find((user) => user.id === userId);
};

export const doNordicLogin = (userId, token, authCtx) => {
  const validUser = authCtx.checkUser(userId, token);
  if (validUser) {
    return validUser;
  } else {
    return {
      error: "INVALID_USER",
    };
  }
};

export const authenticateSession = (config, authCtx) => {
  const token = retrieveToken();
  if (token) {
    // try to authenticate
    fetch("https://fin.account.a2d.tv/rest/user/me?client=mtv-web", {
      mode: "cors",
      headers: {
        Accept: "application/json;charset=utf-8",
        "Content-type": "application/json;v=2",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.json();
        } else {
          if (resp.status === 401) {
            clearToken();
          }
          authCtx.login(null);
          return null;
        }
      })
      .then((json) => {
        if (json) {
          const validUser = findValidUser(json.userId);
          if (validUser) {
            authCtx.login(retrieveToken(), validUser.roles);
          } else {
            authCtx.login(null);
          }
        }
      });
  } else {
    // if no token stored, proceed to showing login form
    authCtx.login(null);
  }
};
