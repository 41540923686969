// Default values for all environments
// Use ECMAScript 5 syntax

// NOTE: changes to this file are executed only at the start of the build. Restart npm run dev after all changes!
module.exports = {
  imageProxy: "https://imageproxy.a2d.tv/?source=",
  nordicImageService: "https://tvmedia.image-service.eu-north-1-prod.vmnd.tv",
  nordicApi:
    "https://tvmedia.content-discovery.cf.eu-north-1-prod.vmnd.tv/api/v1",
  rootId: 1423,
  env: "prod",
  allowedUsers: [
    {
      id: "28c7f9de-5edd-41aa-9b4b-cfa9d7a7794c",
      roles: ["verkosto", "liiga", "mestis", "suomiareena"],
    },
  ],
  filters: [
    {
      title: "Kaikki",
      name: "kaikki",
    },
    {
      title: "Uutiset",
      name: "uutiset",
      rootCategory: 1424,
    },
    {
      title: "Sport",
      name: "sport",
      rootCategory: 1446,
    },
  ],
  liigaFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 1687,
    },
    {
      title: "Maalikoosteet",
      name: "maalikoosteet",
      rootCategory: 1689,
    },
    {
      title: "Huippuhetket",
      name: "huippuhetket",
      rootCategory: 1688,
    },
    {
      title: "Lehdistötilaisuudet",
      name: "lehdistötilaisuudet",
      rootCategory: 1691,
    },
    {
      title: "Muut klipit",
      name: "muut",
      rootCategory: 1692,
    },
  ],
  mestisFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 1610,
    },
    {
      title: "Maalikoosteet",
      name: "maalikoosteet",
      rootCategory: 1614,
    },
    {
      title: "Otteluklipit",
      name: "otteluklipit",
      rootCategory: 1611,
    },
    {
      title: "Haastattelut",
      name: "haastattelut",
      rootCategory: 1612,
    },
    {
      title: "Lisäsisällöt",
      name: "lisäsisällöt",
      rootCategory: 1613,
    },
  ],
  suomiAreenaFilters: [
    {
      title: "Kaikki",
      name: "kaikki",
      rootCategory: 1451,
    },
    {
      title: "Tiistai",
      name: "tiistai",
      rootCategory: 1464,
    },
    {
      title: "Keskiviikko",
      name: "keskiviikko",
      rootCategory: 1465,
    },
    {
      title: "Torstai",
      name: "torstai",
      rootCategory: 1466,
    },
    {
      title: "Perjantai",
      name: "perjantai",
      rootCategory: 1467,
    },
    {
      title: "Klipit",
      name: "klipit",
      rootCategory: 1493,
    },
  ],
};
