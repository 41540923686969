import "./FooterComponent.scss";

const FooterComponent = () => {
  const version = process.env.REACT_APP_VERSION || "dev";

  return (
    <div className="footer-content">
      <div className="footer-content__text">
        <p>© 2024 MTV Oy</p>
        <p>Version: {version}</p>
      </div>
    </div>
  );
};

export default FooterComponent;
