import React from 'react';
import { createRoot } from "react-dom/client";
import { includes } from 'lodash/collection';
import { merge } from 'lodash/object';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './store/auth-context';
import { AppContextProvider } from './store/app-context';
import Logger from 'js-logger';

const envType = process.env.REACT_APP_ENV
const supportedTypes = ['dev', 'test', 'prod']
if (!includes(supportedTypes, envType)) {
  console.log('Only types ' + supportedTypes + ' are supported. See env/index.js and README.md');
}
const base = require('./config')
const env = require('../env/config-' + envType)
const config = merge(base, env)

Logger.debug('Config after merging: ', config)

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <AuthContextProvider>
    <AppContextProvider>
      <BrowserRouter>
        <App config={config} />
      </BrowserRouter>
    </AppContextProvider>
  </AuthContextProvider>
);
